import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import download from '../images/download.png';
import hero from '../images/hero.png';

const IndexPage = () => (
  <Layout>
    <Seo />
    <div className="grid grid-cols-12 gap-4 my-10">
      <div className="place-self-center col-span-12 lg:col-span-5">
        <h1
          className="text-white text-7xl font-extrabold lg:max-w-[30rem]"
          style={{ fontFamily: '"Inter", sans-serif' }}>
          We make high-quality apps
        </h1>
        <p
          className="text-blue-200 text-xl font-light tracking-tight mt-6"
          style={{ fontFamily: '"Inter", sans-serif' }}>
          Every user deserves high-quality apps
        </p>
        <p className="mt-10" style={{ marginLeft: '-3px' }}>
          <a
            href="https://play.google.com/store/apps/dev?id=6690207320389382689&referrer=utm_source%3Dnomad88%26utm_medium%3Dbanner"
            target="_blank">
            <img className="h-16" src={download} />
          </a>
        </p>
      </div>
      <div className="col-span-12 lg:col-span-7 mt-10 lg:mt-0 place-self-center lg:place-self-end">
        <img
          className="w-full max-w-xl"
          src={hero}
          alt="Nomad Music & Nomad Scan"
        />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
